import { Routes } from '@angular/router';
import { DashboardComponent } from './features/views/dashboard/dashboard.component';
import { Page404Component } from './features/views/page404/page404.component';  
import { TodoListComponent } from './features/components/todo-list/todo-list.component';
import { AuthComponent } from './features/views/auth/auth.component';
import { firebaseGuardGuard } from './guards/firebase-guard.guard';
import { LandingComponent } from './features/views/landing/landing.component';
import { RoleGuard } from './services/role-guard.service';

export const routes: Routes = [
  
    { path:'landing', canActivate: [firebaseGuardGuard],  component: LandingComponent , title: 'On eMSP'}, 
    // { path:'dashboard', canActivate: [firebaseGuardGuard],   component: DashboardComponent , title: 'On eMSP | Dashboard'},  
    // { path:'todo', component: TodoListComponent , title: 'On eMSP | ToDo List'}, 
    { path:'login', component: AuthComponent  , title: 'On eMSP | Login'}, 

       // 'supreadmin' | 'admin' | 'superuser' | 'user' | 'guest' | 'banned' | 'deleted'   

    //* CUSTOMERS
    { path:'customers', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] }, 
      title: 'On eMSP | Customers',  
      loadComponent: () => import('./features/views/customers/customers.component').then(c => c.CustomersComponent) },

      { path:'customers/:userId', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },     
        title: 'On eMSP | Customer Info',  
        loadComponent: () => import('./features/views/customers/customer/customer.component').then(c => c.CustomerComponent) },
  
    //* CRM USERS
    { path:'crmusers', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] }, 
      title: 'On eMSP | CRM Users',  
      loadComponent: () => import('./features/views/crm-users/crm-users.component').then(c => c.CrmUsersComponent) },

    //* CRM USERS CHAT
    { path:'crmusers/:chatId', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] }, 
      title: 'On eMSP | CRM User CHAT',  
      loadComponent: () => import('./features/views/crm-users/crm-users.component').then(c => c.CrmUsersComponent) }, 
      
    // RIFID CARDS
    { path:'rifid', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] }, 
      title: 'On eMSP | RIFID cards',  
      loadComponent: () => import('./features/views/rifid-cards/rifid-cards.component').then(c => c.RifidCardsComponent) },
      
    //* RECHARGES 
    { path:'recharges', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] }, 
      title: 'On eMSP | CRM Recharges',  
      loadComponent: () => import('./features/views/recharges/recharges.component').then(c => c.RechargesComponent) },

    //* STATIONS
    { path:'stations', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },  
      title: 'On eMSP | Stations',  
      loadComponent: () => import('./features/views/stations/stations.component').then(c => c.StationsComponent) },

      { path:'stations/:stationId', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] }, 
        title: 'On eMSP | Stations',  
        loadComponent: () => import('./features/views/stations/station/station.component').then(c => c.StationComponent) },
  
    //* PUN
    { path:'pun', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },   
      title: 'On eMSP | PUN History',  
      loadComponent: () => import('./features/views/pun/pun.component').then(c => c.PunComponent) },
    { path:'pun/json/:punId', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },  
      title: 'On eMSP | PUN Json',  
      loadComponent: () => import('./features/views/pun/components/shell/shell.component').then(c => c.ShellComponent) },        

    //* TRANSLATIONS  
    { path:'translations',  canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin' ] },    
      title: 'On eMSP | Translations',  
      loadComponent: () => import('./features/views/translations/translations.component').then(c => c.TranslationsComponent) },
        

    //* COMPANY CALENDAR
    { path:'companycal', canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin'] },   
        title: 'On eMSP | Company Calendar',  
        loadComponent: () => import('./features/views/company-calendar/company-calendar.component').then(c => c.CompanyCalendarComponent) },
    //* PERSONAL CALENDAR
    { path:'personalcal', canActivate: [firebaseGuardGuard],  
        title: 'On eMSP | Personal Calendar',  
        loadComponent: () => import('./features/views/personal-calendar/personal-calendar.component').then(c => c.PersonalCalendarComponent) }, 
      
    //* CHATGPT interface
    { path:'gpt',  canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin' ] },     
        title: 'On eMSP | Ask ChatGPT',  
        loadComponent: () => import('./features/components/ai-contents/ai-contents.component').then(c => c.AiContentsComponent) },

    // ANALITYCS 
    { path:'analytics/delivered/2023',  canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },    
      title: 'On eMSP | Analytis | Delivered',  
      loadComponent: () => import('./features/views/analytics/delivered/delivered.component').then(c => c.DeliveredComponent) },
    
    { path:'analytics/dash',  canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },    
      title: 'On eMSP | Analytis | Delivered',  
      loadComponent: () => import('./features/views/analytics/dash-board/dash-board.component').then(c => c.DashBoardComponent) },

    { path:'analytics/aireports',  canActivate: [firebaseGuardGuard, RoleGuard],   data: { roles: ['superadmin', 'admin', 'superuser'] },    
      title: 'On eMSP | AI Reports',  
      loadComponent: () => import('./features/views/analytics/smart-reporting/smart-reporting.component').then(c => c.SmartReportingComponent) },

        
  
    // queste rotte devono stare esattamente qui e in quest'ordine 
    { path: '',   redirectTo: '/landing', pathMatch: 'full' }, 
    { path: '**', canActivate: [firebaseGuardGuard],   component: Page404Component }
  ];
