import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { FirebaseAuthService } from '../features/views/auth/services/firebase-auth.service'; 
import { catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api'; 

export const appInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
     const firebaseAuthService = inject(FirebaseAuthService);
     const messageService = inject(MessageService);

    //  const token =  firebaseAuthService.currentUserToken() ;    
    //  const modifiedReq = token ? req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`), })  :  req;

    const fbToken = firebaseAuthService.currentUserToken();
    const modifiedReq = req.headers.has('Authorization') ? req  : (fbToken) ? req.clone({ headers: req.headers.set('Authorization', `Bearer ${fbToken}`) }) : req;
   
     return next(modifiedReq).pipe(
      catchError((error) => { 
            messageService.add({
                severity: 'error',
                summary: 'Errore',
                detail: `Errore ${error.status}: ${error.message}`,
                life: 10000 // Il toast rimarrà visibile per 5 secondi
            });  
            return throwError(() => error);
      })
    );

};

